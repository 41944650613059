<template>
  <div class="ccc">
    <div class="sec1">
      <div class="con">
        <div class="sec1_head">
          <span class="main">BIGC Club </span>
          <span class="sub">for creators</span>
        </div>
        <div class="sec1_tt m_hide">
          빅크 클럽으로 팬·독자와의 프라이빗한 공간을 만드세요 <br />나만의
          콘텐츠로 꾸준히 수익화하세요
        </div>
        <div class="sec1_tt m_show">
          빅크 클럽에서 나만의 콘텐츠로 <br />꾸준히 수익화 하세요
        </div>
        <div class="sec1_desc">
          클럽 오픈 시 팔로워의 0.1~10%까지도 유료로
          <br class="m_show" />전환됩니다. (2022년 상반기 빅크 크리에이터 평균)
          <br />일회성 콘텐츠 판매가 아닌 유료 회원을
          <br class="m_show" />장기적으로 계속 참여 시키세요.
        </div>

        <button class="sec1_btn" @click="actions.goToSnsLogin()">
          라이브 클럽 시작해보기
        </button>

        <img
          class="m_hide"
          style="width: 100%; height: 560px"
          src="/assets/web_image/club/sec1_pc_main.png"
        />

        <img
          class="m_show"
          style="width: 448px; height: 405px"
          src="/assets/web_image/club/sec1_mo_main.png"
        />
      </div>
    </div>

    <div class="sec2">
      <div class="con">
        <div class="sec2_tt">
          한계 없는 크리에이티브를 위한 <br />
          빅크 테마 라이브러리
        </div>
        <div class="sec2_desc m_hide">
          빅크 데이터팀에서 분석한 수익화 가능성이 높은 콘텐츠를 분석했어요.
          주제 카테고리부터 디자인까지 빅크 테마 라이브러리를 <br />
          200% 활용해보세요. 망설임 필요 없이 내 콘텐츠를 더 간단하고 쉽게,
          하지만 매력적으로 만들수 있어요.
        </div>
        <div class="sec2_desc m_show">
          빅크 데이터팀에서 수익화 가능성이 높은 콘텐츠를 <br />분석해서
          준비했습니다. 더 간단하고 쉽게 <br />내 콘텐츠를 매력적으로
          만들어보세요.
        </div>

        <div class="sec2_card_con">
          <div class="sec2_card_list">
            <div>
              <div class="sec2_card_tt">
                초대하기
                <span class="sec2_card_desc"
                  >좋은 사람과 다양한 주제로 소통하는 시간</span
                >
              </div>

              <div class="sec2_s_card_con">
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_11.png"
                />
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_12.png"
                />
              </div>
            </div>

            <div>
              <div class="sec2_card_tt">
                돈벌기
                <span class="sec2_card_desc">재테크 팁 전수의 시간</span>
              </div>

              <div class="sec2_s_card_con">
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_21.png"
                />
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_22.png"
                />
              </div>
            </div>

            <div>
              <div class="sec2_card_tt">
                잘 놀기
                <span class="sec2_card_desc">취미에 딥 다이브하는 시간</span>
              </div>

              <div class="sec2_s_card_con">
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_31.png"
                />
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_32.png"
                />
              </div>
            </div>
          </div>

          <img
            class="sec2_card_big"
            src="/assets/web_image/club/sec2_main.png"
          />

          <div class="sec2_card_list">
            <div>
              <div class="sec2_card_tt">
                배우기
                <span class="sec2_card_desc"
                  >성장을 위한 멘토가 되어주는 시간</span
                >
              </div>

              <div class="sec2_s_card_con">
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_41.png"
                />
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_42.png"
                />
              </div>
            </div>

            <div>
              <div class="sec2_card_tt">
                마음 돌보기
                <span class="sec2_card_desc"
                  >고민하는 마음에 해답을 찾아주는 시간</span
                >
              </div>

              <div class="sec2_s_card_con">
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_51.png"
                />
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_52.png"
                />
              </div>
            </div>

            <div>
              <div class="sec2_card_tt">
                건강하기
                <span class="sec2_card_desc">단단한 체력을 쌓아주는 시간</span>
              </div>

              <div class="sec2_s_card_con">
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_61.png"
                />
                <img
                  class="sec2_s_card"
                  src="/assets/web_image/club/sec2_62.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sec3">
      <div class="con">
        <div class="sec3_head m_hide">
          빅크 스튜디오는 수익화에 필요한 <br />모든 운영 비용을 초절감 합니다
        </div>
        <div class="sec3_head m_show">
          빅크 스튜디오는 수익화에 <br />필요한 모든 운영 비용을 <br />
          초절감 합니다
        </div>
        <div class="sec3_desc m_hide">
          콘텐츠 수익화, 이벤트 운영에 필요한 운영 스탭, 디자이너, 개발자, 별도
          툴 없이 원클릭으로 시작할 수 있어요.
        </div>
        <div class="sec3_desc m_show">
          콘텐츠 수익화, 이벤트 운영에 필요한 <br />운영 스탭, 디자이너, 개발자,
          별도 툴 없이 <br />원클릭으로 시작할 수 있어요.
        </div>

        <div class="sec3_card_con">
          <div class="sec3_card_item bg-green">
            <img
              class="sec3_card_img"
              src="/assets/web_image/club/sec3_1.png"
            />
            <div class="sec3_card_tt">라이브까지 한 번에!</div>
            <div class="sec3_card_desc">
              기존 라이브 따로, VOD 따로, 콘텐츠에 따라 별도 툴을<br />
              사용해야 했던 어려움을 해결했습니다. 빅크에서<br />
              라이브부터 모든 형태의 콘텐츠 판매가 한번에 가능합니다.
            </div>
          </div>
          <div class="sec3_card_item bg-purple">
            <img
              class="sec3_card_img"
              src="/assets/web_image/club/sec3_2.png"
            />
            <div class="sec3_card_tt">힘든 운영의 자동화!</div>
            <div class="sec3_card_desc">
              콘텐츠 기획, 제작보다 더 손이 많이 가던 운영의 번거로움을<br />
              해결했습니다. 빅크에서는 콘텐츠 판매 또는 라이브 일자만<br />
              세팅하면 모든 운영은 자동으로 가능합니다.
            </div>
          </div>
          <div class="sec3_card_item bg-red">
            <img
              class="sec3_card_img"
              src="/assets/web_image/club/sec3_3.png"
            />
            <div class="sec3_card_tt">고객 케어도 빅크가!</div>
            <div class="sec3_card_desc">
              환불, 정산, 고객 CS까지 크리에이터가 해결해야하는<br />
              문제를 해결했습니다. 빅크 운영팀에서<br />
              모든 커뮤니케이션과 절차를 진행해드립니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sec4">
      <div class="sec4_tt m_hide">
        나만의 프라이빗 클럽에 팬·구독자를 초대하세요
      </div>
      <div class="sec4_tt m_show">
        나만의 프라이빗 클럽에<br />
        팬·구독자를 초대하세요
      </div>
      <button class="sec4_btn" @click="actions.goToSnsLogin()">
        크리에이터로 시작하기
      </button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { onBeforeUnmount, onMounted } from "vue";
import DatalayerEvents from "../../../helper/datalayerEvents";

export default {
  name: "ClubLanding",
  setup() {
    const router = useRouter();
    const dataLayer = new DatalayerEvents();

    const actions = {
      goToSnsLogin: () => {
        let eventObj = dataLayer.club2;
        dataLayer.push(eventObj);
        router.push({ name: "auth.login" });
      },
    };

    const initCss = () => {
      const html = document.getElementsByTagName("html")[0].style;
      const app = document.getElementById("app").style;
      const body = document.body.style;
      html.position = "static";
      html.overflowY = "visible";
      body.position = "static";
      body.overflow = "visible";
      body.height = "auto";
      app.overflowX = "visible";
      app.overflowY = "visible";
    };

    const resetCss = () => {
      const html = document.getElementsByTagName("html")[0].style;
      const app = document.getElementById("app").style;
      const body = document.body.style;
      html.position = "static";
      html.overflowY = "hidden";
      body.position = "static";
      body.overflow = "hidden";
      body.height = "100%";
      app.overflowY = "scroll";
    };

    onMounted(() => {
      initCss();
    });

    onBeforeUnmount(() => {
      resetCss();
    });

    return { actions };
  },
};
</script>

<style src="./style.css" scoped></style>
